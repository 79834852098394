import { v4 as uuid } from "uuid";

import * as cognito from "./cognito";

// Function for checking if the signed in user is the owner
export const isUserOwner = async (userId) => {
  const cognitoSession = await cognito.getSession();
  if (!cognitoSession) {
    localStorage.removeItem("sessionUser");
    return false;
  }

  const currentUserId = localStorage.getItem("sessionUser");
  console.log(currentUserId);

  if (!currentUserId) {
    return false;
  }

  // check that the jwt is still valid (or that the cognito session exists?)

  return currentUserId === userId;
};

export const generateUUID = () => {
  return uuid();
};

const secretPassword = process.env.REACT_APP_SECRET_PASSWORD ?? false;

export const isValidPassword = ({ password }) =>
  !secretPassword || password === secretPassword;
