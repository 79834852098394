import React, { useState } from "react";

import { Alert, Button, Link, TableCell, TableRow } from "@mui/material";

import { format } from "date-fns";

import * as api from "../../../../../../utils/api";

const PendingRentalRequestsListItem = ({ row }) => {
  const [isCancelled, setIsCancelled] = useState(false);

  const setCacheDirty = () => {
    localStorage.setItem(
      "rentalRequestsByUser",
      JSON.stringify({ isCacheDirty: true })
    );
  };

  console.log(row);

  return (
    <TableRow
      key={`${row.user_id}-${row.requested_start_date}`}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell>
        <Link
          href={`/listing/${row.item_id}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {`${row.item_title}`}
        </Link>
      </TableCell>
      <TableCell>{format(new Date(row.requested_start_date), "PP")}</TableCell>
      <TableCell>{format(new Date(row.requested_end_date), "PP")}</TableCell>
      <TableCell>
        {isCancelled ? (
          <Alert severity="success">Request cancelled.</Alert>
        ) : (
          <Button
            variant="outlined"
            size="small"
            onClick={async () => {
              await api.cancelRentalRequest({ rentalRequest: row });
              setIsCancelled(true);
              setCacheDirty();
            }}
          >
            Cancel
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default PendingRentalRequestsListItem;
