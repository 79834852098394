import React from "react";

import { Box, Container, Typography } from "@mui/material";

import Logo from "../../components/Logo";

const WIP = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Container>
        <Box>
          <Logo />
          <Typography>This page is under construction.</Typography>
          <Typography>Please come back later.</Typography>
        </Box>
      </Container>
    </div>
  );
};

export default WIP;
