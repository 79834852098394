import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#A6D785", // guacamole
    },
    secondary: {
      main: "#12664F", // bottle green - not sure I like this.
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Nunito",
      textTransform: "none",
    },
  },
});

export default theme;
