import React, { useState } from "react";

import { Autocomplete, TextField } from "@mui/material";

import * as mapbox from "../../utils/mapbox";

const SelectLocation = ({ value, onLocationChange, isError, disableError }) => {
  const [location, setLocation] = useState(value);
  const [autocompleteLocations, setAutocompleteLocations] = useState([]);

  const handleCityChange = async (value) => {
    setLocation(value);

    const res = await mapbox.fetchPlace(location);
    !autocompleteLocations.includes(value) &&
      res.features &&
      setAutocompleteLocations(res.features.map((place) => place.place_name));
  };

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={autocompleteLocations}
      defaultValue={value}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Location"
          helperText={isError ? "Select a location" : null}
          error={isError}
        />
      )}
      onInputChange={async (event) => {
        disableError();
        if (event) {
          await handleCityChange(event.target.value);
        }
      }}
      onChange={(_event, value) => {
        onLocationChange(value);
      }}
      fullWidth
    />
  );
};

export default SelectLocation;
