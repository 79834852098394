import React, { useState, useEffect } from "react";

import { Alert, Box, CircularProgress } from "@mui/material";

import RentalRecordsList from "./RentalRecordsList";

import * as api from "../../../../utils/api";

const RentalRecords = () => {
  const [present, setPresent] = useState([]);
  const [future, setFuture] = useState([]);
  const [past, setPast] = useState([]);

  const [isFetching, setIsFetching] = useState(true);
  const [fetchingError, setFetchingError] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    try {
      const fetchData = async () => {
        const currentUserId = localStorage.getItem("sessionUser");
        const response = await api.getRentalRecords({
          userId: currentUserId,
        });
        setPresent(response.rental_records.present);
        setPast(response.rental_records.past);
        setFuture(response.rental_records.future);

        localStorage.setItem(
          "rentalRequestsByUser",
          JSON.stringify({
            rentalRequests: response.rental_requests,
            isCacheDirty: false,
            timestamp: new Date(),
          })
        );
      };

      // Check the cache for rental requests, and make sure it's still valid.
      const rentalRecords = localStorage.getItem("rentalRecords");
      const rentalRecordsJson = rentalRecords
        ? JSON.parse(rentalRecords)
        : null;

      // Clear cache after 5 mins
      const nMinsFromNow = new Date(new Date().getTime() + 5 * 60000);
      if (
        !rentalRecordsJson ||
        (rentalRecordsJson &&
          (rentalRecordsJson.isCacheDirty ||
            new Date(rentalRecordsJson.timestamp) > nMinsFromNow))
      ) {
        fetchData();
      } else {
        setPresent(rentalRecordsJson.rentalRecords.present);
        setPast(rentalRecordsJson.rentalRecords.past);
        setFuture(rentalRecordsJson.rentalRecords.future);
      }
    } catch (err) {
      setFetchingError(true);
    }
    setIsFetching(false);
  }, []);

  return (
    <div>
      {isFetching ? (
        <CircularProgress />
      ) : (
        <div>
          {fetchingError ? (
            <div>
              <Alert severity="error">
                Unable to fetch rental requests. Please try again later.
              </Alert>
            </div>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <RentalRecordsList
                title="Currently active rentals"
                rentalHistory={present}
                defaultExpanded={true}
              />

              <RentalRecordsList
                title="Upcoming rentals"
                rentalHistory={future}
              />

              <RentalRecordsList title="Past rentals" rentalHistory={past} />
            </Box>
          )}
        </div>
      )}
    </div>
  );
};

export default RentalRecords;
