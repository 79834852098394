import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Container,
  TextField,
} from "@mui/material";

import * as cognito from "../../../utils/cognito";

import PageWrapper from "../../../components/Wrappers/PageWrapper";
import SearchProfileHeader from "../../../components/Headers/SearchProfileHeader";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageWrapper>
      <SearchProfileHeader />
      <Container maxWidth="md" sx={{ marginTop: "40px" }}>
        <Card sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <Container>
            <CardHeader
              title="Forgot password"
              titleTypographyProps={{ variant: "h5" }}
              subheader="Enter the email address you used to create your account and we'll send you a reset code."
              subheaderTypographyProps={{ variant: "body1" }}
            />
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  name="email"
                  value={email}
                  onChange={(event) => {
                    setIsError(false);
                    setEmail(event.target.value);
                  }}
                  fullWidth
                />
              </Box>
            </CardContent>
            <CardActions
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "center",
              }}
            >
              {isError ? (
                <Alert severity="error">Oops! An error occurred</Alert>
              ) : null}

              <Button
                variant="contained"
                size="medium"
                disabled={isLoading}
                onClick={async () => {
                  setIsLoading(true);
                  try {
                    await cognito.sendCode(email);

                    navigate("/forgot-password/reset", { state: { email } });
                  } catch (err) {
                    console.log(err);
                    setIsError(true);
                  }

                  setIsLoading(false);
                }}
              >
                Send reset code
              </Button>
            </CardActions>
          </Container>
        </Card>
      </Container>
    </PageWrapper>
  );
};

export default ForgotPassword;
