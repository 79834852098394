import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";

import {
  Alert,
  Avatar,
  Box,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";

import SearchProfileHeader from "../../../components/Headers/SearchProfileHeader";
import PageWrapper from "../../../components/Wrappers/PageWrapper";
import ItemPreviewCard from "../../../components/ItemPreviewCard";

import * as api from "../../../utils/api";

const PublicProfile = () => {
  const { profile_id } = useParams();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [dateCreated, setDateCreated] = useState("");
  const [items, setItems] = useState([]);

  const [isFetching, setIsFetching] = useState(true);
  const [fetchingError, setFetchingError] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    try {
      const fetchData = async () => {
        const userResponse = await api.getUserByProfileId({
          profileId: profile_id,
        });
        if (!userResponse.user.profile_id) {
          setFetchingError(true);
          return;
        }

        const itemsResponse = await api.getListingsByUserId({
          userId: userResponse.user.user_id,
        });

        setFirstName(userResponse.user.first_name);
        setLastName(userResponse.user.last_name);
        setProfilePicture(userResponse.user.profile_picture);
        setDateCreated(userResponse.user.date_created);

        setItems(itemsResponse.items);
      };
      fetchData();
    } catch (err) {
      console.log(err);
      setFetchingError(true);
    }

    setIsFetching(false);
  }, [profile_id]);

  return (
    <PageWrapper>
      <SearchProfileHeader
        query={""}
        location={""}
        category={"All categories"}
      />

      <Container maxWidth="md" sx={{ marginTop: "40px" }}>
        <Card sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <Container>
            {isFetching ? (
              <CircularProgress />
            ) : (
              <div>
                <CardHeader
                  title={`${firstName} ${lastName}`}
                  titleTypographyProps={{ variant: "h5" }}
                  avatar={
                    <Avatar
                      src={profilePicture} // if no image, it'll just do a generic avatar
                      sx={{ width: 24, height: 24 }}
                    />
                  }
                  subheader={
                    dateCreated
                      ? `Member since ${format(
                          new Date(dateCreated),
                          "MMMM yyyy"
                        )}`
                      : null
                  }
                />

                {isFetching ? (
                  <CircularProgress />
                ) : (
                  <div>
                    {fetchingError ? (
                      <div>
                        <Alert severity="error">
                          Unable to fetch account. Please try again later.
                        </Alert>
                      </div>
                    ) : (
                      <div>
                        <CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <Typography variant="h5">Items</Typography>
                            {items.length === 0 ? (
                              <Typography variant="body1">
                                This user has no items.
                              </Typography>
                            ) : null}

                            <Grid
                              container
                              spacing={{ xs: 2, md: 3 }}
                              columns={1}
                            >
                              {items.map(
                                (
                                  {
                                    item_id,
                                    title,
                                    photos,
                                    location,
                                    is_available,
                                  },
                                  index
                                ) => (
                                  <Grid item key={index} xs={2}>
                                    <ItemPreviewCard
                                      itemId={item_id}
                                      name={title}
                                      imageUrl={photos[0]}
                                      location={location}
                                      isAvailable={is_available}
                                    />
                                  </Grid>
                                )
                              )}
                            </Grid>
                          </Box>
                        </CardContent>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </Container>
        </Card>
      </Container>
    </PageWrapper>
  );
};

export default PublicProfile;
