import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SelectLocation from "../SelectLocation";

import categories from "../../assets/data/categories";

const SearchBar = ({ searchTermProp, categoryProp, locationProp }) => {
  const navigate = useNavigate();

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const [searchTerm, setSearchTerm] = useState(searchTermProp ?? "");
  const [category, setCategory] = useState(categoryProp ?? "All categories");
  const [location, setLocation] = useState(
    locationProp ?? localStorage.getItem("location") ?? ""
  );

  const [isError, setIsError] = useState(false);

  const handleSearch = () => {
    if (!location) {
      setIsError(true);
      return;
    }
    navigate(
      `/listings/search?location=${location}&category=${category}&query=${searchTerm}`
    );
  };

  const searchBars = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          gap: "10px",
          width: "100%",
        }}
      >
        <TextField
          id="search-id"
          label="Search for anything..."
          variant="outlined"
          name="searchTerm"
          value={searchTerm}
          onChange={(event) => {
            setIsError(false);
            setSearchTerm(event.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
          fullWidth
        />
        <FormControl fullWidth>
          <Select
            labelId="select-label"
            id="category"
            value={category}
            onChange={(event) => {
              setIsError(false);
              setCategory(event.target.value);
            }}
          >
            {["All categories", ...categories].map((item) => (
              <MenuItem id={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <SelectLocation
          value={location}
          onLocationChange={(value) => {
            setLocation(value);
          }}
          disableError={() => setIsError(false)}
          isError={isError}
        />

        <IconButton
          type="submit"
          aria-label="search"
          onClick={() => handleSearch()}
        >
          <SearchIcon />
        </IconButton>
      </Box>
    );
  };

  return !desktop ? (
    <Accordion sx={{ width: "100%", marginBottom: "20px" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Search for anything...</Typography>
      </AccordionSummary>
      <AccordionDetails>{searchBars()}</AccordionDetails>
    </Accordion>
  ) : (
    searchBars()
  );
};

export default SearchBar;
