import React from "react";
import PageFooter from "../../Footers/PageFooter";

const PageWrapper = ({ children }) => {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {/* TODO: add Header here */}
      <div>{children}</div>
      <PageFooter />
    </div>
  );
};

export default PageWrapper;
