import {
  Avatar,
  Box,
  Container,
  Card,
  CardHeader,
  CardContent,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { format } from "date-fns";

const RentalHistory = ({ title, rentalHistory }) => {
  return (
    <Container maxWidth="md" sx={{ marginTop: "10px" }}>
      <Card sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Container>
          <CardHeader title={title} titleTypographyProps={{ variant: "h6" }} />
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell align="right">Date borrowed</TableCell>
                    <TableCell align="right">Date returned</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rentalHistory.map((row) => (
                    <TableRow
                      key={`${row.user_id}-${row.date_borrowed}`}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Avatar src={row.user.profile_picture} size="small" />
                          <Link
                            href={`/profile/${row.user.profile_id}`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {`${row.user.first_name} ${row.user.last_name}`}
                          </Link>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        {format(new Date(row.date_borrowed), "PP")}
                      </TableCell>
                      <TableCell align="right">
                        {format(new Date(row.date_returned), "PP")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </CardContent>
        </Container>
      </Card>
    </Container>
  );
};

export default RentalHistory;
