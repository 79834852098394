import React, { useState, useEffect } from "react";

import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Container,
  Link,
  TextField,
  Typography,
} from "@mui/material";

import PageWrapper from "../../components/Wrappers/PageWrapper";
import LogoProfileHeader from "../../components/Headers/LogoProfileHeader";

import * as api from "../../utils/api";
import * as s3 from "../../utils/s3";

const Profile = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profileId, setProfileId] = useState("");
  const [profilePicture, setProfilePicture] = useState("");

  // Not editable, but needed for createOrUpdateUser
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [dateCreated, setDateCreated] = useState("");

  const [isFetchLoading, setIsFetchLoading] = useState(true);
  const [fetchingError, setFetchingError] = useState(false);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const uploadFile = async (file) => {
    const s3File = await s3.uploadFileToS3(file, `${userId}/${profileId}`);
    return s3File.location;
  };

  useEffect(() => {
    try {
      const fetchData = async () => {
        setIsFetchLoading(true);
        const userId = localStorage.getItem("sessionUser");
        setUserId(userId);

        const response = await api.getUser({ userId: userId });

        if (!response.user.user_id) {
          setFetchingError(true);
          setIsFetchLoading(false);
          return;
        }

        setFirstName(response.user.first_name);
        setLastName(response.user.last_name);
        setEmail(response.user.email);
        setProfilePicture(response.user.profile_picture);
        setDateCreated(response.user.date_created);
        setProfileId(response.user.profile_id);
      };
      fetchData();
    } catch (err) {
      console.log(err);
      setFetchingError(true);
    }
    setIsFetchLoading(false);
  }, []);

  return (
    <PageWrapper>
      <LogoProfileHeader />

      <Container maxWidth="md" sx={{ marginTop: "40px" }}>
        <Card sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <Container>
            <CardHeader
              title="Profile"
              titleTypographyProps={{ variant: "h5" }}
            />
            {isFetchLoading ? (
              <CircularProgress />
            ) : (
              <div>
                {fetchingError ? (
                  <div>
                    <Alert severity="error">
                      Oops! This item doesn't exist.
                    </Alert>
                  </div>
                ) : (
                  <div>
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <Link href={`/profile/${profileId}`}>
                          <Typography>View my public profile</Typography>
                        </Link>
                        <Avatar src={profilePicture} />
                        <TextField
                          id="profilePicture"
                          label="New profile picture"
                          variant="outlined"
                          name="profilePicture"
                          type="file"
                          inputProps={{
                            multiple: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={async (event) => {
                            setIsUploadLoading(true);
                            setIsError(false);

                            const file = event.target.files[0];
                            const s3ImageUrl = await uploadFile(file);
                            console.log(s3ImageUrl);
                            setProfilePicture(s3ImageUrl);
                            setIsUploadLoading(false);
                          }}
                          fullWidth
                        />
                        <TextField
                          id="username"
                          label="Username"
                          variant="outlined"
                          name="username"
                          value={profileId}
                          disabled
                          fullWidth
                        />
                        <TextField
                          id="first_name"
                          label="First name"
                          variant="outlined"
                          name="firstName"
                          value={firstName}
                          onChange={(event) => {
                            setIsError(false);
                            setIsSuccess(false);
                            setFirstName(event.target.value);
                          }}
                          fullWidth
                        />
                        <TextField
                          id="last_name"
                          label="Last name"
                          variant="outlined"
                          name="lastName"
                          value={lastName}
                          onChange={(event) => {
                            setIsError(false);
                            setIsSuccess(false);
                            setLastName(event.target.value);
                          }}
                          fullWidth
                        />
                      </Box>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {isError ? (
                        <Alert severity="error">{errorMessage}</Alert>
                      ) : null}

                      {isSuccess ? (
                        <Alert severity="success">{successMessage}</Alert>
                      ) : null}

                      <Button
                        variant="contained"
                        fullWidth
                        disabled={isUploadLoading}
                        onClick={async () => {
                          setIsUploadLoading(true);
                          try {
                            await api.createOrUpdateUser({
                              userId: userId,
                              firstName: firstName,
                              lastName: lastName,
                              email: email,
                              profilePicture: profilePicture,
                              profileId: profileId,
                              dateCreated: dateCreated ?? new Date(),
                            });

                            setIsError(false);
                            setIsSuccess(true);
                            setSuccessMessage("Edits successfully saved!");
                          } catch (err) {
                            setIsError(true);
                            setIsSuccess(false);
                            setErrorMessage("Oops! Something went wrong.");
                          }
                          setIsUploadLoading(false);
                        }}
                      >
                        Save
                      </Button>
                    </CardActions>
                  </div>
                )}
              </div>
            )}
          </Container>
        </Card>
      </Container>
    </PageWrapper>
  );
};

export default Profile;
