import React from "react";

import { Box, Container, Typography } from "@mui/material";

const PageFooter = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#12664F",
        marginTop: "40px",
        paddingTop: "40px",
        paddingBottom: "40px",
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
        width: "100%",
        flexShrink: "0",
      }}
    >
      <Container>
        <Typography variant="body1">© RNTYR 2022</Typography>
      </Container>
    </Box>
  );
};

export default PageFooter;
