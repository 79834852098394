import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Alert, CircularProgress, Container } from "@mui/material";

import ItemCard from "../../components/ItemCard";
import SearchProfileHeader from "../../components/Headers/SearchProfileHeader";
import PageWrapper from "../../components/Wrappers/PageWrapper";
import ContactCard from "../../components/ContactCard";
import RentalHistory from "../../components/RentalHistory";

import * as api from "../../utils/api";
import * as utils from "../../utils/utils";

import { format } from "date-fns";

// Fetch listing info based on id in url parameter
const Listing = () => {
  const { item_id } = useParams();
  const [item, setItem] = useState({});
  const [user, setUser] = useState({});
  const [rentalHistory, setRentalHistory] = useState({});
  const [isUserOwner, setIsUserOwner] = useState("");

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [borrowRequestMessage, setBorrowRequestMessage] = useState(
    "Hi! Can I please borrow this uwu"
  );
  const [requestedStartDate, setRequestedStartDate] = useState(null);
  const [requestedEndDate, setRequestedEndDate] = useState(null);

  const [unavailableDates, setUnavailableDates] = useState(new Set());

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isErrorSubmittingRequest, setIsErrorSubmittingRequest] =
    useState(false);
  const [isSuccessSubmittingRequest, setIsSuccessSubmittingRequest] =
    useState(false);
  const [contactCardErrorMessage, setContactCardErrorMessage] = useState("");

  const getDatesInRange = (returnSet, startDate, endDate) => {
    const date = new Date(startDate.getTime());

    while (date <= endDate) {
      returnSet.add(format(new Date(date), "PP"));
      date.setDate(date.getDate() + 1);
    }
  };

  useEffect(() => {
    setIsUserLoggedIn(localStorage.getItem("sessionUser") !== null);

    try {
      setIsLoading(true);
      const fetchData = async () => {
        const itemResponse = await api.getItem({
          item_id: item_id,
        });
        if (!itemResponse.item.location) {
          setIsError(true);
          setErrorMessage("Item not found.");
          return;
        }
        setItem(itemResponse.item);
        setRentalHistory(itemResponse.rental_history);

        const userResponse = await api.getUser({
          userId: itemResponse.item.user_id,
        });
        if (!userResponse.user.user_id) {
          setIsError(true);
          setErrorMessage("User not found.");
          return;
        }
        setUser(userResponse.user);

        const isOwner = await utils.isUserOwner(itemResponse.item.user_id);
        setIsUserOwner(isOwner);
        setIsLoading(false);

        // Returns a set containing all the individual dates
        // that an item is unavailable so we can then mark them
        // as disabled on the calendar.
        // God only knows why we can't put this into a separate function.
        const unavailableDates = new Set();
        [
          itemResponse.rental_history.present,
          ...itemResponse.rental_history.future,
        ].forEach((rental) => {
          getDatesInRange(
            unavailableDates,
            new Date(rental.date_borrowed),
            new Date(rental.date_returned)
          );
        });
        setUnavailableDates(unavailableDates);
      };
      fetchData();
    } catch (err) {
      console.log(err);
      setIsError(true);
    }
  }, [item_id]);

  const submitBorrowRequest = async () => {
    setIsSubmitting(true);

    // Validate that the dates are valid
    try {
      if (!Date.parse(requestedStartDate)) {
        throw new Error("Please enter a valid date for the start date");
      }

      if (!Date.parse(requestedEndDate)) {
        throw new Error("Please enter a valid date for the end date");
      }

      if (new Date(requestedStartDate) > new Date(requestedEndDate)) {
        throw new Error("End date must be after start date.");
      }

      // Make sure that there are no unavailable dates between the start and end date
      const dateSet = new Set();
      getDatesInRange(
        dateSet,
        new Date(requestedStartDate),
        new Date(requestedEndDate)
      );
      dateSet.forEach((date) => {
        if (unavailableDates.has(date)) {
          throw new Error("Selected range contains invalid dates.");
        }
      });
    } catch (err) {
      console.log(err.message);
      setIsErrorSubmittingRequest(true);
      setContactCardErrorMessage(err.message);
      setIsSubmitting(false);
      return;
    }

    try {
      await api.createRentalRequest({
        borrowerId: localStorage.getItem("sessionUser"),
        ownerId: item.user_id,
        itemId: item_id,
        message: borrowRequestMessage,
        dateSubmitted: new Date(),
        requestedStartDate: requestedStartDate,
        requestedEndDate: requestedEndDate,

        // And to make our dynamo life easier:
        itemTitle: item.title,

        // And to make sending emails a little nicer:
        startDatePretty: format(requestedStartDate, "PP"),
        endDatePretty: format(requestedEndDate, "PP"),
      });

      setIsSuccessSubmittingRequest(true);
    } catch (err) {
      setIsErrorSubmittingRequest(true);
      setContactCardErrorMessage(
        "Error submitting your request. Please try again later."
      );
    }
    setIsSubmitting(false);
  };

  return (
    <PageWrapper>
      <SearchProfileHeader query={""} />

      {isLoading ? (
        <CircularProgress />
      ) : (
        <div>
          {isError ? (
            <Container>
              <Alert severity="error">{errorMessage}</Alert>
            </Container>
          ) : (
            <div>
              <ItemCard
                item_id={item_id}
                title={item.title}
                description={item.description}
                imageUrls={item.photos}
                dateCreated={item.date_created}
                isAvailable={item.is_available}
                location={item.location}
                isUserOwner={isUserOwner}
              />
              {/* Only show the contact card if the user is NOT the owner */}
              {isUserOwner ? null : (
                <ContactCard
                  username={user.profile_id}
                  firstName={user.first_name}
                  lastName={user.last_name}
                  dateCreated={user.date_created}
                  profilePicture={user.profile_picture}
                  isUserLoggedIn={isUserLoggedIn}
                  borrowRequestMessage={borrowRequestMessage}
                  setBorrowRequestMessage={setBorrowRequestMessage}
                  requestedStartDate={requestedStartDate}
                  setRequestedStartDate={setRequestedStartDate}
                  requestedEndDate={requestedEndDate}
                  setRequestedEndDate={setRequestedEndDate}
                  onSubmitBorrowRequest={submitBorrowRequest}
                  errorSubmittingRequest={isErrorSubmittingRequest}
                  setIsErrorSubmittingRequest={setIsErrorSubmittingRequest}
                  successSubmittingRequest={isSuccessSubmittingRequest}
                  isLoading={isSubmitting}
                  errorMessage={contactCardErrorMessage}
                  unavailableDates={unavailableDates}
                />
              )}

              {isUserOwner ? (
                <RentalHistory
                  title="Current rental"
                  rentalHistory={
                    rentalHistory.present.item_id ? [rentalHistory.present] : []
                  }
                />
              ) : null}

              {isUserOwner ? (
                <RentalHistory
                  title="Upcoming rentals"
                  rentalHistory={rentalHistory.future}
                />
              ) : null}

              {isUserOwner ? (
                <RentalHistory
                  title="Rental history"
                  rentalHistory={rentalHistory.past}
                />
              ) : null}
            </div>
          )}
        </div>
      )}
    </PageWrapper>
  );
};

export default Listing;
