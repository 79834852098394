import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { Card, CardActionArea, CardContent, Typography } from "@mui/material";

const CategoryCard = ({ backgroundColour, title, link }) => {
  const location = localStorage.getItem("location");

  return (
    <Card
      sx={{
        backgroundColor: backgroundColour,
        width: "100%",
        paddingTop: "10px",
        paddingBottom: "10px",
        borderRadius: "40px",
      }}
    >
      <CardActionArea
        component={RouterLink}
        to={`/listings/search?location=${location}&category=${title}&query=`}
      >
        <CardContent>
          <Typography
            variant="body1"
            sx={{ textAlign: "center", color: "white" }}
          >
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CategoryCard;
