import React, { useState, useEffect } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import RentalRequestCard from "./RentalRequestCard";

import * as utils from "../../../utils/utils";
import * as api from "../../../utils/api";

const RentalRequestsTab = () => {
  const [unaddressed, setUnaddressed] = useState([]);
  const [accepted, setAccepted] = useState([]);
  const [rejected, setRejected] = useState([]);

  const [isFetching, setIsFetching] = useState(true);
  const [fetchingError, setFetchingError] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    try {
      const fetchData = async () => {
        const currentUserId = localStorage.getItem("sessionUser");
        const response = await api.getRentalRequestsByOwner({
          ownerId: currentUserId,
        });
        setUnaddressed(response.rental_requests.unaddressed);
        setAccepted(response.rental_requests.accepted);
        setRejected(response.rental_requests.rejected);

        localStorage.setItem(
          "rentalRequests",
          JSON.stringify({
            rentalRequests: response.rental_requests,
            isCacheDirty: false,
            timestamp: new Date(),
          })
        );
      };

      // Check the cache for rental requests, and make sure it's still valid.
      const rentalRequests = localStorage.getItem("rentalRequests");
      const rentalRequestsJson = rentalRequests
        ? JSON.parse(rentalRequests)
        : null;

      // TTL: 5 mins
      const nMinsFromNow = new Date(new Date().getTime() + 5 * 60000);
      if (
        !rentalRequestsJson ||
        (rentalRequestsJson &&
          (rentalRequestsJson.isCacheDirty ||
            new Date(rentalRequestsJson.timestamp) < nMinsFromNow))
      ) {
        fetchData();
      } else {
        setUnaddressed(rentalRequestsJson.rentalRequests.unaddressed);
        setAccepted(rentalRequestsJson.rentalRequests.accepted);
        setRejected(rentalRequestsJson.rentalRequests.rejected);
      }
    } catch (err) {
      setFetchingError(true);
    }
    setIsFetching(false);
  }, []);

  const getAccordion = (listOfThings) => {
    return listOfThings.map((rentalRequest) => {
      return (
        <div key={utils.generateUUID()}>
          <Divider />
          <RentalRequestCard rentalRequest={rentalRequest} />
        </div>
      );
    });
  };

  return (
    <div>
      {isFetching ? (
        <CircularProgress />
      ) : (
        <div>
          {fetchingError ? (
            <Alert severity="error">
              Unable to fetch rental requests. Please try again later.
            </Alert>
          ) : (
            <div>
              <Accordion
                defaultExpanded={unaddressed.length > 0}
                sx={{ width: "100%" }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Pending requests</Typography>
                </AccordionSummary>
                <AccordionDetails>{getAccordion(unaddressed)}</AccordionDetails>
              </Accordion>

              <Accordion sx={{ width: "100%" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Accepted requests</Typography>
                </AccordionSummary>
                <AccordionDetails>{getAccordion(accepted)}</AccordionDetails>
              </Accordion>

              <Accordion sx={{ width: "100%" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Rejected requests</Typography>
                </AccordionSummary>
                <AccordionDetails>{getAccordion(rejected)}</AccordionDetails>
              </Accordion>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RentalRequestsTab;
