import S3 from "react-aws-s3";

export const uploadFileToS3 = async (file, dirName) => {
  const config = {
    bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
    dirName: dirName,
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
    s3Url: process.env.REACT_APP_S3_URL,
  };

  const ReactS3Client = new S3(config);

  return ReactS3Client.uploadFile(file, file.name);
};
