import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Container,
  Link,
  TextField,
  Typography,
} from "@mui/material";

import * as cognito from "../../../../utils/cognito";

import PageWrapper from "../../../../components/Wrappers/PageWrapper";
import SearchProfileHeader from "../../../../components/Headers/SearchProfileHeader";

const VerifyAccount = () => {
  const location = useLocation();

  let email;
  try {
    email = location.state.email;
  } catch (err) {
    email = null;
  }

  const [verificationCode, setVerificationCode] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <PageWrapper>
      <SearchProfileHeader />

      <Container maxWidth="md" sx={{ marginTop: "40px" }}>
        <Card sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <Container>
            <CardHeader
              title="Verify Account"
              titleTypographyProps={{ variant: "h5" }}
            />
            {!email ? (
              <Alert severity="error">
                An error occurred. Please try again.
              </Alert>
            ) : (
              <div>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <TextField
                      disabled
                      id="email"
                      label="Email"
                      variant="outlined"
                      name="email"
                      value={email}
                      fullWidth
                    />
                    <TextField
                      id="verification_code"
                      label="Verification Code"
                      variant="outlined"
                      name="password"
                      value={verificationCode}
                      onChange={(event) => {
                        setIsError(false);
                        setVerificationCode(event.target.value);
                      }}
                      fullWidth
                    />
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {isError ? (
                    <Alert severity="error">{errorMessage}</Alert>
                  ) : null}

                  {isSuccess ? (
                    <Alert severity="success">
                      Your account has been successfully verified!{" "}
                      <Link href="/login">
                        <Typography>
                          Log in with your account details.
                        </Typography>
                      </Link>
                    </Alert>
                  ) : null}

                  <Button
                    variant="contained"
                    sx={{ width: "50%" }}
                    disabled={isLoading}
                    onClick={async () => {
                      setIsLoading(true);

                      try {
                        await cognito.verifyCode(email, verificationCode);
                        setIsSuccess(true);
                      } catch (err) {
                        setIsError(true);
                        setErrorMessage(
                          "Something went wrong, please try again."
                        );
                        setIsLoading(false);
                      }
                    }}
                  >
                    Verify Your Account
                  </Button>
                </CardActions>
              </div>
            )}
          </Container>
        </Card>
      </Container>
    </PageWrapper>
  );
};

export default VerifyAccount;
