import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const ItemPreviewCard = ({ itemId, name, imageUrl, location }) => {
  return (
    <Card>
      <CardActionArea component={RouterLink} to={`/listing/${itemId}`}>
        <CardMedia
          component="img"
          height="140"
          image={imageUrl}
          alt="item image"
        />
        <CardContent>
          <Typography gutterBottom variant="body1">
            <b>{name}</b>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <LocationOnIcon fontSize="small" />
            <Typography variant="body1" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
              {location}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ItemPreviewCard;
