import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";

import * as api from "../../../utils/api";

import SearchProfileHeader from "../../../components/Headers/SearchProfileHeader";
import ItemPreviewCard from "../../../components/ItemPreviewCard";
import PageWrapper from "../../../components/Wrappers/PageWrapper";

const SearchResults = () => {
  const { search } = useLocation();

  const location = new URLSearchParams(search).get("location");
  const category = new URLSearchParams(search).get("category");
  const query = new URLSearchParams(search).get("query");

  const [items, setItems] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const resultsPluralOrSingular = (len) => {
    return len === 1 ? "result" : "results";
  };

  const loadMore = async () => {
    setIsLoadingMore(true);
    const response = await api.getListings({
      location: location,
      searchTerm: query,
      category: category === "All categories" ? "" : category,
      lastEvaluatedKey: lastEvaluatedKey,
    });

    setItems([...items, ...response.items]);
    setLastEvaluatedKey(response.lastEvaluatedKey);
    setIsLoadingMore(false);
  };

  useEffect(() => {
    try {
      setIsLoading(true);
      const fetchData = async () => {
        const response = await api.getListings({
          location: location,
          searchTerm: query,
          category: category === "All categories" ? "" : category,
          lastEvaluatedKey: null,
        });
        setItems(response.items);
        setLastEvaluatedKey(response.lastEvaluatedKey);
        setIsLoading(false);
      };
      fetchData();
    } catch (err) {
      setIsLoading(false);
    }
  }, [query, category, location]);

  return (
    <PageWrapper>
      <SearchProfileHeader
        query={query}
        location={location}
        category={category}
      />

      {isLoading ? (
        <CircularProgress />
      ) : (
        <Container maxWidth="md" sx={{ marginTop: "20px" }}>
          <Typography variant="body1" gutterBottom>
            Your seach for <b>{query === "" ? "all items" : query}</b> in{" "}
            <b>{category === "All categories" ? "all categories" : category}</b>{" "}
            in <b>{location}</b> returned <b>{items.length}</b>{" "}
            {resultsPluralOrSingular(items.length)}
          </Typography>
          <Grid width="80%" container spacing={{ xs: 2 }}>
            {items.map(
              ({ item_id, title, photos, is_available, location }, index) => (
                <Grid item xs={12} sm={6} md={6} key={index}>
                  <ItemPreviewCard
                    itemId={item_id}
                    name={title}
                    imageUrl={photos[0]}
                    location={location}
                  />
                </Grid>
              )
            )}
          </Grid>

          {/* Button to View More if there is more to view */}
          {lastEvaluatedKey ? (
            <Box
              sx={{
                display: "flex",
                padding: "20px",
                justifyContent: "center",
              }}
            >
              {isLoadingMore ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={loadMore}
                >
                  Load more
                </Button>
              )}
            </Box>
          ) : null}
        </Container>
      )}
    </PageWrapper>
  );
};

export default SearchResults;
