import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import * as cognito from "../../../utils/cognito";
import * as api from "../../../utils/api";
import * as utils from "../../../utils/utils";

import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Container,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";

import PageWrapper from "../../../components/Wrappers/PageWrapper";
import SearchProfileHeader from "../../../components/Headers/SearchProfileHeader";

const Register = () => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profileId, setProfileId] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dynamoId = utils.generateUUID();

  return (
    <PageWrapper>
      <SearchProfileHeader />

      <Container maxWidth="md" sx={{ marginTop: "40px" }}>
        <Card sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <Container>
            <CardHeader
              title="Register"
              titleTypographyProps={{ variant: "h5" }}
            />
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <TextField
                  id="username"
                  label="Username"
                  variant="outlined"
                  name="profileId"
                  value={profileId}
                  onChange={(event) => {
                    setIsError(false);
                    setProfileId(event.target.value);
                  }}
                  fullWidth
                />
                <TextField
                  id="first_name"
                  label="First Name"
                  variant="outlined"
                  name="firstName"
                  value={firstName}
                  onChange={(event) => {
                    setIsError(false);
                    setFirstName(event.target.value);
                  }}
                  fullWidth
                />
                <TextField
                  id="last_name"
                  label="Last Name"
                  variant="outlined"
                  name="lastName"
                  value={lastName}
                  onChange={(event) => {
                    setIsError(false);
                    setLastName(event.target.value);
                  }}
                  fullWidth
                />
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  name="email"
                  value={email}
                  onChange={(event) => {
                    setIsError(false);
                    setEmail(event.target.value);
                  }}
                  fullWidth
                />
                <TextField
                  id="password"
                  label="Password"
                  variant="outlined"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(event) => {
                    setIsError(false);
                    setPassword(event.target.value);
                  }}
                  fullWidth
                />
                <TextField
                  id="confirm_password"
                  label="Confirm Password"
                  variant="outlined"
                  name="confirmPassword"
                  type="password"
                  value={confirmPassword}
                  onChange={(event) => {
                    setIsError(false);
                    setConfirmPassword(event.target.value);
                  }}
                  fullWidth
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={termsAndConditions}
                      onChange={() => {
                        setIsError(false);
                        setTermsAndConditions(!termsAndConditions);
                      }}
                    />
                  }
                  label="I accept the terms and conditions"
                />
              </Box>
            </CardContent>
            <CardActions
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "center",
              }}
            >
              {isError ? <Alert severity="error">{errorMessage}</Alert> : null}

              <Button
                variant="contained"
                sx={{ width: "50%" }}
                disabled={isLoading}
                onClick={async () => {
                  setIsLoading(true);

                  // Validate that the terms and conditions have been accepted
                  if (!termsAndConditions) {
                    setIsError(true);
                    setErrorMessage(
                      "You must accept the terms and conditions otherwise perish."
                    );
                    setIsLoading(false);
                    return;
                  }

                  // Validate that the password is long enough (8 characters)
                  if (password.length < 8) {
                    setIsError(true);
                    setErrorMessage(
                      "Passwords must be at least 8 characters long."
                    );
                    setIsLoading(false);
                    return;
                  }

                  // Validate that the password matches the confirmPassword
                  if (password !== confirmPassword) {
                    setIsError(true);
                    setErrorMessage("Passwords must match.");
                    setIsLoading(false);
                    return;
                  }

                  try {
                    // Validate that a user with the given email doesn't already exist
                    const user = await api.getUserByProfileId({
                      profileId: profileId,
                    });
                    if (user) {
                      setIsError(true);
                      setErrorMessage(
                        "User with this username already exists, please select a new one."
                      );
                      setIsLoading(false);
                      return;
                    }

                    await cognito.signUpUserWithEmail(
                      email,
                      dynamoId,
                      password
                    );
                    await api.createOrUpdateUser({
                      userId: dynamoId,
                      firstName: firstName,
                      lastName: lastName,
                      email: email,
                      profilePicture: "",
                      profileId: profileId,
                      dateCreated: new Date(),
                    });

                    navigate("/verify-account", { state: { email } });
                  } catch (err) {
                    setIsError(true);
                    setErrorMessage("Something went wrong, please try again.");
                  }

                  setIsLoading(false);
                }}
              >
                Register
              </Button>
              <Button
                variant="outlined"
                sx={{ width: "50%" }}
                component={RouterLink}
                to="/login"
              >
                Log In
              </Button>
            </CardActions>
          </Container>
        </Card>
      </Container>
    </PageWrapper>
  );
};

export default Register;
