import React from "react";

import { Box, Container, useTheme, useMediaQuery } from "@mui/material";

import Logo from "../../Logo";
import SearchBar from "../../SearchBar";
import ProfileBubble from "../../ProfileBubble";

const SearchProfileHeader = ({ query, location, category }) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        boxShadow: "0 5px 5px -5px rgba(50, 50, 93, 0.25)",
      }}
    >
      <Container>
        {/* Rejig the order a little bit when the screen is small */}
        {!desktop ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Logo />
              <ProfileBubble />
            </Box>

            <SearchBar
              searchTermProp={query}
              locationProp={location}
              categoryProp={category}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Logo />
            <SearchBar
              searchTermProp={query}
              locationProp={location}
              categoryProp={category}
            />
            <ProfileBubble />
          </Box>
        )}
      </Container>
    </div>
  );
};

export default SearchProfileHeader;
