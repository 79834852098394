import React from "react";

import { Card, Container, Typography } from "@mui/material";

import LogoProfileHeader from "../../../components/Headers/LogoProfileHeader";
import PageWrapper from "../../../components/Wrappers/PageWrapper";

const NotFound = () => {
  return (
    <PageWrapper>
      <LogoProfileHeader />
      <Container maxWidth="md" sx={{ marginTop: "40px" }}>
        <Card sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <Container>
            <Typography variant="h5">Page Not Found</Typography>
          </Container>
        </Card>
      </Container>
    </PageWrapper>
  );
};

export default NotFound;
