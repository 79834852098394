import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  AddCircleOutline,
  Folder,
  Login,
  Logout,
  PersonAdd,
  Settings,
} from "@mui/icons-material";

import * as cognito from "../../utils/cognito";

const ProfileBubble = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    cognito.signOut();
    setUser(null);
    localStorage.removeItem("sessionUser");
    navigate("/");
  };

  useEffect(() => {
    const fetchUser = async () => {
      const user = await cognito.getSession();
      setUser(user);
    };
    fetchUser();
  }, []);

  return (
    <div>
      <IconButton
        onClick={handleClick}
        size="medium"
        sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Avatar sx={{ width: 32, height: 32 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {user ? (
          // If logged in:
          <div>
            <MenuItem component={Link} to="/account">
              <ListItemIcon>
                <Settings />
              </ListItemIcon>{" "}
              Account Settings
            </MenuItem>
            <Divider />
            <MenuItem component={Link} to="/profile">
              <Avatar />
              Profile
            </MenuItem>
            <Divider />
            <MenuItem component={Link} to="/listing/new">
              <ListItemIcon>
                <AddCircleOutline />
              </ListItemIcon>
              Create Listing
            </MenuItem>
            <Divider />
            <MenuItem component={Link} to="/listings">
              <ListItemIcon>
                <Folder />
              </ListItemIcon>
              My Listings
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => handleLogOut()}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              Logout
            </MenuItem>
          </div>
        ) : (
          // Otherwise display 'Log in' and 'Register'
          <div>
            <MenuItem component={Link} to="/login">
              <ListItemIcon>
                <Login />
              </ListItemIcon>
              Log In
            </MenuItem>
            <MenuItem component={Link} to="/register">
              <ListItemIcon>
                <PersonAdd />
              </ListItemIcon>
              Register
            </MenuItem>
          </div>
        )}
      </Menu>
    </div>
  );
};

export default ProfileBubble;
