import React from "react";

import {
  Link,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";

import { format } from "date-fns";

const RejectedRentalRequestsList = ({ rejectedRentalRequests }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Item</TableCell>
          <TableCell>From</TableCell>
          <TableCell>To</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rejectedRentalRequests.map((row) => (
          <TableRow
            key={`${row.user_id}-${row.requested_start_date}`}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell>
              <Link
                href={`/listing/${row.item_id}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {`${row.item_title}`}
              </Link>
            </TableCell>
            <TableCell>{format(new Date(row.requested_start_date), "PP")}</TableCell>
            <TableCell>{format(new Date(row.requested_end_date), "PP")}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default RejectedRentalRequestsList;
