import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Container,
  FormControlLabel,
  ImageList,
  ImageListItem,
  Link,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import LogoProfileHeader from "../../../components/Headers/LogoProfileHeader";
import PageWrapper from "../../../components/Wrappers/PageWrapper";

import * as api from "../../../utils/api";

const EditListing = () => {
  const { item_id } = useParams();

  const [item, setItem] = useState({});

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const [isAvailable, setIsAvailable] = useState(false);

  const [isFetchLoading, setIsFetchLoading] = useState(true);
  const [fetchingError, setFetchingError] = useState(false);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    try {
      setIsFetchLoading(true);
      const fetchData = async () => {
        const response = await api.getItem({
          item_id: item_id,
        });

        if (!response.item.location) {
          setFetchingError(true);
          setIsFetchLoading(false);
          return;
        }

        setItem(response.item);
        setTitle(response.item.title);
        setDescription(response.item.description);
        setImageUrls(response.item.photos);
        setIsAvailable(response.item.is_available);
      };
      fetchData();
    } catch (err) {
      setFetchingError(true);
    }
    setIsFetchLoading(false);
  }, [item_id]);

  return (
    <PageWrapper>
      <LogoProfileHeader />

      <Container maxWidth="md" sx={{ marginTop: "40px" }}>
        <Card sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <Container>
            <CardHeader
              title="Edit your listing"
              titleTypographyProps={{ variant: "h5" }}
            />

            {isFetchLoading ? (
              <CircularProgress />
            ) : (
              <div>
                {fetchingError ? (
                  <div>
                    <Alert severity="error">
                      Oops! This item doesn't exist.
                    </Alert>
                  </div>
                ) : (
                  <div>
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <TextField
                          id="title"
                          label="Title"
                          variant="outlined"
                          name="title"
                          value={title}
                          onChange={(event) => {
                            setIsError(false);
                            setIsSuccess(false);
                            setTitle(event.target.value);
                          }}
                          fullWidth
                        />
                        <TextField
                          id="description"
                          label="Description"
                          variant="outlined"
                          name="description"
                          value={description}
                          onChange={(event) => {
                            setIsError(false);
                            setIsSuccess(false);
                            setDescription(event.target.value);
                          }}
                          multiline
                          fullWidth
                        />

                        <ImageList cols={3}>
                          {imageUrls.map((item, index) => (
                            <ImageListItem key={index}>
                              <img
                                src={item}
                                srcSet={item}
                                alt={item}
                                loading="lazy"
                              />
                            </ImageListItem>
                          ))}
                        </ImageList>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isAvailable}
                              onChange={(event) => {
                                setIsError(false);
                                setIsSuccess(false);
                                setIsAvailable(event.target.checked);
                              }}
                            />
                          }
                          label={
                            isAvailable
                              ? "Listing is currently active. Mark as inactive?"
                              : "Listing is currently inactive. Mark as active?"
                          }
                        />
                      </Box>
                    </CardContent>

                    <CardActions
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {isError ? (
                        <Alert severity="error">{errorMessage}</Alert>
                      ) : null}

                      {isSuccess ? (
                        <Alert severity="success">
                          {successMessage}{" "}
                          <Link href={`/listing/${item_id}`}>
                            <Typography>View your updated listing</Typography>
                          </Link>
                        </Alert>
                      ) : null}

                      <Button
                        variant="contained"
                        fullWidth
                        disabled={isUploadLoading}
                        onClick={async () => {
                          setIsUploadLoading(true);
                          try {
                            await api.updateListing({
                              item: item,
                              title: title,
                              description: description,
                              isAvailable: isAvailable,
                            });

                            setIsError(false);
                            setIsSuccess(true);
                            setSuccessMessage("Edits successfully saved!");
                          } catch (err) {
                            setIsError(true);
                            setIsSuccess(false);
                            setErrorMessage("Oops! Something went wrong.");
                          }

                          setIsUploadLoading(false);
                        }}
                      >
                        Save
                      </Button>

                      <Button
                        variant="outlined"
                        fullWidth
                        disabled={isUploadLoading}
                        onClick={async () => {
                          try {
                            setIsError(true);
                            setIsSuccess(false);
                            setErrorMessage(
                              "Oops! Delete hasn't been implemented yet."
                            );
                          } catch (err) {
                            console.log(err);
                          }
                        }}
                      >
                        Delete listing
                      </Button>
                    </CardActions>
                  </div>
                )}
              </div>
            )}
          </Container>
        </Card>
      </Container>
    </PageWrapper>
  );
};

export default EditListing;
