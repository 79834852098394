import { Divider } from "@mui/material";

import RentalRecords from "./RentalRecords";
import InactiveRentalRequests from "./InactiveRentalRequests";

const RentalRecordsTab = () => {
  return (
    <div>
      <InactiveRentalRequests />
      <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
      <RentalRecords />
    </div>
  );
};

export default RentalRecordsTab;
