import React, { useState, useEffect } from "react";

import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Container,
  TextField,
} from "@mui/material";

import LogoProfileHeader from "../../components/Headers/LogoProfileHeader";
import PageWrapper from "../../components/Wrappers/PageWrapper";

import * as api from "../../utils/api";
import * as cognito from "../../utils/cognito";

const Account = () => {
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [fetchingError, setFetchingError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    try {
      setIsFetching(true);
      const fetchData = async () => {
        const currentUserId = localStorage.getItem("sessionUser");
        const response = await api.getUser({ userId: currentUserId });

        if (!response || !response.user) {
          setIsFetching(false);
          setFetchingError(true);
          return;
        }

        setEmail(response.user.email);
      };
      fetchData();
    } catch (err) {
      console.log(err);
      setFetchingError(true);
    }
    setIsFetching(false);
  }, []);

  return (
    <PageWrapper>
      <LogoProfileHeader />

      <Container maxWidth="md" sx={{ marginTop: "40px" }}>
        <Card sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <Container>
            <CardHeader
              title="My Account"
              titleTypographyProps={{ variant: "h5" }}
            />

            {isFetching ? (
              <CircularProgress />
            ) : (
              <div>
                {fetchingError ? (
                  <div>
                    <Alert severity="error">
                      Unable to fetch account. Please try again later.
                    </Alert>
                  </div>
                ) : (
                  <div>
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <TextField
                          id="email"
                          label="Email"
                          variant="outlined"
                          name="email"
                          value={email}
                          disabled
                          fullWidth
                        />
                        <TextField
                          id="old-password"
                          label="Current password"
                          variant="outlined"
                          type="password"
                          name="oldPassword"
                          value={oldPassword}
                          onChange={(event) => {
                            setIsError(false);
                            setIsSuccess(false);
                            setOldPassword(event.target.value);
                          }}
                          fullWidth
                        />
                        <TextField
                          id="new-password"
                          label="New password"
                          variant="outlined"
                          type="password"
                          name="newPassword"
                          value={newPassword}
                          onChange={(event) => {
                            setIsError(false);
                            setIsSuccess(false);
                            setNewPassword(event.target.value);
                          }}
                          fullWidth
                        />
                        <TextField
                          id="confirm-new-password"
                          label="Confirm new password"
                          variant="outlined"
                          type="password"
                          name="confirmNewPassword"
                          value={confirmNewPassword}
                          onChange={(event) => {
                            setIsError(false);
                            setIsSuccess(false);
                            setConfirmNewPassword(event.target.value);
                          }}
                          fullWidth
                        />
                      </Box>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      {isError ? (
                        <Alert severity="error">{errorMessage}</Alert>
                      ) : null}

                      {isSuccess ? (
                        <Alert severity="success">
                          Your account has been successfully updated!
                        </Alert>
                      ) : null}

                      <Button
                        variant="contained"
                        disabled={isLoading}
                        onClick={async () => {
                          setIsLoading(true);

                          // Validate that the password is long enough
                          if (newPassword === "" && newPassword.length < 8) {
                            setIsError(true);
                            setIsLoading(false);
                            setErrorMessage(
                              "New password must be at least 8 characters long."
                            );
                          }

                          // Make sure passwords match
                          else if (
                            newPassword !== "" &&
                            newPassword !== confirmNewPassword
                          ) {
                            setIsError(true);
                            setErrorMessage("Passwords do not match");
                          }

                          // Ok good, now change the password
                          else {
                            try {
                              await cognito.changePassword(
                                oldPassword,
                                newPassword
                              );
                              setIsSuccess(true);
                            } catch (err) {
                              setIsError(true);
                              setErrorMessage("Unable to change password.");
                              console.log(err);
                            }
                          }

                          setIsLoading(false);
                        }}
                      >
                        Save
                      </Button>
                    </CardActions>
                  </div>
                )}
              </div>
            )}
          </Container>
        </Card>
      </Container>
    </PageWrapper>
  );
};

export default Account;
