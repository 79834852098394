import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import LogoProfileHeader from "../../../components/Headers/LogoProfileHeader";
import SelectLocation from "../../../components/SelectLocation";
import PageWrapper from "../../../components/Wrappers/PageWrapper";

import * as api from "../../../utils/api";
import * as utils from "../../../utils/utils";
import * as s3 from "../../../utils/s3";

import categories from "../../../assets/data/categories";

const CreateListing = () => {
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [location, setLocation] = useState("");
  const [category, setCategory] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [price, setPrice] = useState("");

  const itemId = utils.generateUUID();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    try {
      const fetchData = async () => {
        const userId = localStorage.getItem("sessionUser");
        setUserId(userId);
      };
      fetchData();
    } catch (err) {
      setIsError(true);
      setErrorMessage("Something went wrong. Please try again.");
    }
  }, []);

  const uploadFiles = async (files) => {
    const s3Files = await Promise.all(
      files.map(
        async (file) => await s3.uploadFileToS3(file, `${userId}/${itemId}`)
      )
    );

    return s3Files.map((file) => file.location);
  };

  return (
    <PageWrapper>
      <LogoProfileHeader />

      <Container maxWidth="md" sx={{ marginTop: "40px" }}>
        <Card sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <Container>
            <CardHeader
              title="Post a New Item Listing"
              titleTypographyProps={{ variant: "h5" }}
            />
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <TextField
                  id="title"
                  label="Title"
                  variant="outlined"
                  name="title"
                  value={title}
                  onChange={(event) => {
                    setIsError(false);
                    setTitle(event.target.value);
                  }}
                  fullWidth
                />

                <SelectLocation
                  value={location}
                  onLocationChange={(value) => {
                    console.log(value);
                    setLocation(value);
                  }}
                  disableError={() => setIsError(false)}
                />

                <FormControl>
                  <InputLabel id="id-label">Category</InputLabel>
                  <Select
                    labelId="select-label"
                    id="category"
                    value={category}
                    label="Category"
                    onChange={(event) => {
                      setIsError(false);
                      setCategory(event.target.value);
                    }}
                  >
                    {categories.map((category) => (
                      <MenuItem value={category}>{category}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  id="description"
                  label="Description"
                  variant="outlined"
                  name="description"
                  multiline
                  rows={2}
                  value={description}
                  onChange={(event) => {
                    setIsError(false);
                    setDescription(event.target.value);
                  }}
                  fullWidth
                />
                <TextField
                  id="contactEmail"
                  label="Contact email"
                  variant="outlined"
                  name="contactEmail"
                  value={contactEmail}
                  onChange={(event) => {
                    setIsError(false);
                    setContactEmail(event.target.value);
                  }}
                  fullWidth
                />

                <TextField
                  id="price"
                  label="Price"
                  variant="outlined"
                  name="price"
                  value={price}
                  onChange={(event) => {
                    setIsError(false);
                    setPrice(event.target.value);
                  }}
                  fullWidth
                />
                <TextField
                  id="imageURLs"
                  label="Upload images of your item"
                  variant="outlined"
                  name="imageUrls"
                  type="file"
                  inputProps={{
                    multiple: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => {
                    setIsError(false);

                    const files = [...event.target.files];
                    setUploadedFiles(files);
                  }}
                  fullWidth
                />
              </Box>
            </CardContent>
            <CardActions
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "center",
              }}
            >
              {isError ? <Alert severity="error">{errorMessage}</Alert> : null}

              <Button
                variant="contained"
                size="medium"
                disabled={isLoading}
                onClick={async () => {
                  setIsLoading(true);
                  if (!title || !description || !contactEmail || !location) {
                    setIsError(true);
                    setErrorMessage(
                      "Unable to create listing because one or more mandatory fields are blank." +
                        "The following fields are mandatory: title, description, contact email, location."
                    );
                    return;
                  }

                  try {
                    const s3ImageUrls = await uploadFiles(uploadedFiles);

                    await api.createOrUpdateListing({
                      itemId: itemId,
                      location: location,
                      userId: userId,
                      dateCreated: new Date(),
                      category: category,
                      photos: s3ImageUrls,
                      title: title,
                      description: description,
                      price: price,
                      isAvailable: true,
                      contactEmail: contactEmail,
                    });

                    navigate(`/listing/${itemId}`);
                  } catch (err) {
                    console.log(err);

                    setIsError(true);
                    setErrorMessage("Something went wrong. Please try again.");
                  }

                  setIsLoading(false);
                }}
              >
                Post
              </Button>
            </CardActions>
          </Container>
        </Card>
      </Container>
    </PageWrapper>
  );
};

export default CreateListing;
