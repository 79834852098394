import React, { useState, useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";

import ItemPreviewCard from "../../ItemPreviewCard";

import * as api from "../../../utils/api";

const MyListingsTab = () => {
  const [listings, setListings] = useState([]);

  const [isFetching, setIsFetching] = useState(true);
  const [fetchingError, setFetchingError] = useState(false);

  const setCacheDirty = () => {
    localStorage.setItem("listings", JSON.stringify({ isCacheDirty: true }));
  };

  useEffect(() => {
    setIsFetching(true);
    try {
      const fetchData = async () => {
        const currentUserId = localStorage.getItem("sessionUser");
        const response = await api.getListingsByUserId({
          userId: currentUserId,
        });
        setListings(response.items);
        localStorage.setItem(
          "listings",
          JSON.stringify({ listings: response.items, isCacheDirty: false })
        );
      };

      // Check the cache for listings, and make sure it's still valid.
      const listings = localStorage.getItem("listings");
      const listingsJson = listings ? JSON.parse(listings) : null;

      if (!listingsJson || (listingsJson && listingsJson.isCacheDirty)) {
        fetchData();
      } else {
        setListings(listingsJson.listings);
      }
    } catch (err) {
      setFetchingError(true);
    }
    setIsFetching(false);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      {isFetching ? (
        <CircularProgress />
      ) : (
        <div>
          {fetchingError ? (
            <div>
              <Alert severity="error">
                Unable to fetch listings. Please try again later.
              </Alert>
            </div>
          ) : (
            <div>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={1}>
                {listings.map(
                  (
                    { item_id, title, photos, location, is_available },
                    index
                  ) => (
                    <Grid item key={index} xs={2}>
                      <ItemPreviewCard
                        itemId={item_id}
                        name={title}
                        imageUrl={photos[0]}
                        location={location}
                        isAvailable={is_available}
                      />
                    </Grid>
                  )
                )}
              </Grid>

              {!isFetching && listings.length === 0 ? (
                <Typography variant="body1">You have no listings!</Typography>
              ) : null}

              <Button
                variant="contained"
                fullWidth
                component={RouterLink}
                to="/listing/new"
                onClick={() => setCacheDirty()}
              >
                Create Listing
              </Button>
            </div>
          )}
        </div>
      )}
    </Box>
  );
};

export default MyListingsTab;
