import { Link } from "@mui/material";

import logo from "../../assets/logo/logo_black_transparent_horiz.png";

const Logo = () => {
  return (
    <Link href="/">
      <img src={logo} alt="RNTYR Logo Black" width="250px" height="auto" />
    </Link>
  );
};

export default Logo;
