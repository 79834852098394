const apiEndpoint = process.env.REACT_APP_API_GATEWAY_ENDPOINT;

// Return the ${limit} latest listings in a ${location}
export const getLatestListings = async ({ location, limit }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
  };

  const params = new URLSearchParams({
    location: location,
    limit: limit,
  });

  const response = await fetch(
    apiEndpoint + "/getLatestListings?" + params.toString(),
    requestOptions
  );

  const responseJson = await response.json();
  return responseJson;
};

// Search for listings in a ${location} with the given ${searchTerm}
export const getListings = async ({
  // Mandatory fields
  location,
  searchTerm,

  // Optional fields
  category,
  lastEvaluatedKey,
}) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
  };

  const params = new URLSearchParams({
    location: location,
    category: category,
    search_term: searchTerm,
    last_evaluated_key: JSON.stringify(lastEvaluatedKey),
  });

  const response = await fetch(
    apiEndpoint + "/getListings?" + params.toString(),
    requestOptions
  );
  const responseJson = await response.json();
  return responseJson;
};

export const getListingsByUserId = async ({ userId }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
  };

  const response = await fetch(
    apiEndpoint + "/getListingsByUser?user_id=" + userId,
    requestOptions
  );
  const responseJson = await response.json();
  return responseJson;
};

export const getItem = async ({ item_id }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
  };

  const params = new URLSearchParams({
    item_id: item_id,
  });

  const response = await fetch(
    apiEndpoint + "/getItem?" + params.toString(),
    requestOptions
  );
  const responseJson = await response.json();
  return responseJson;
};

// NOTE: this is just CREATE
export const createOrUpdateListing = async ({
  // Mandatory fields
  itemId,
  location,
  userId,
  dateCreated,
  category,

  // Optional fields
  photos,
  title,
  description,
  price,
  isAvailable,
  contactEmail,
  dateUpdated,
}) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      item_id: itemId,
      location: location,
      user_id: userId,
      date_created: dateCreated,
      category: category,
      photos: photos ?? "",
      title: title,
      description: description ?? "",
      price: price ?? "",
      is_available: isAvailable,
      contact_email: contactEmail,
      date_updated: dateUpdated ?? "",
    }),
  };

  const response = await fetch(apiEndpoint + "/createListing", requestOptions);

  if (response.ok) {
    console.log("ok!");
  } else {
    console.log("not ok!");
    throw new Error("Failed to create item listing");
  }

  return response;
};

export const updateListing = async ({
  item,
  title,
  description,
  isAvailable,
}) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      item: item,
      title: title,
      description: description,
      is_available: isAvailable,
      date_updated: new Date(),
    }),
  };

  const response = await fetch(apiEndpoint + "/updateListing", requestOptions);

  if (response.ok) {
    console.log("ok!");
  } else {
    console.log("not ok!");
    throw new Error("Failed to create item listing");
  }

  return response;
};

export const getUser = async ({ userId }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
  };

  const response = await fetch(
    apiEndpoint + "/getUser?user_id=" + userId,
    requestOptions
  );
  const responseJson = await response.json();
  return responseJson;
};

// get user by username aka profile_id
export const getUserByProfileId = async ({ profileId }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
  };

  const response = await fetch(
    apiEndpoint + "/getUser?profile_id=" + profileId,
    requestOptions
  );
  const responseJson = await response.json();
  return responseJson;
};

export const createOrUpdateUser = async ({
  // Mandatory fields
  userId,
  firstName,
  lastName,
  email,
  dateCreated,
  profileId,

  // Optional fields
  profilePicture,
}) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
    body: JSON.stringify({
      user_id: userId,
      profile_id: profileId,
      first_name: firstName,
      last_name: lastName,
      email: email,
      profile_picture: profilePicture ?? "",
      date_created: dateCreated,
    }),
  };

  const response = await fetch(apiEndpoint + "/createUser", requestOptions);

  if (!response.ok) {
    throw new Error("Failed to create item listing");
  }

  const responseJson = await response.json();
  return responseJson;
};

export const getProfile = async ({ username }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
  };

  const response = await fetch(
    apiEndpoint + "/getProfile?username=" + username,
    requestOptions
  );
  const responseJson = await response.json();
  return responseJson;
};

export const createOrUpdateProfile = async ({
  email,
  username,
  firstName,
  lastName,
  profilePicture,
  dateCreated,
}) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
    body: JSON.stringify({
      username: username,
      email: email,
      first_name: firstName,
      last_name: lastName,
      profile_picture: profilePicture,
      date_created: dateCreated,
    }),
  };

  const response = await fetch(apiEndpoint + "/createProfile", requestOptions);

  if (!response.ok) {
    throw new Error("Failed to create or update profile");
  }

  const responseJson = await response.json();
  return responseJson;
};

export const createRentalRequest = async ({
  borrowerId,
  ownerId,
  itemId,
  message,
  dateSubmitted,
  requestedStartDate,
  requestedEndDate,
  itemTitle,
  startDatePretty,
  endDatePretty,
}) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
    body: JSON.stringify({
      item_id: itemId,
      user_id: borrowerId,
      owner_id: ownerId,
      message: message,
      date_submitted: dateSubmitted,
      requested_start_date: requestedStartDate,
      requested_end_date: requestedEndDate,
      item_title: itemTitle,
      start_date_pretty: startDatePretty,
      end_date_pretty: endDatePretty,
    }),
  };

  const response = await fetch(
    apiEndpoint + "/createRentalRequest",
    requestOptions
  );

  if (!response.ok) {
    throw new Error("Failed to create rental request");
  }

  const responseJson = await response.json();
  return responseJson;
};

export const respondToRentalRequest = async ({
  itemId,
  ownerId,
  dateSubmitted,
  accept, // boolean
}) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
    body: JSON.stringify({
      item_id: itemId,
      owner_id: ownerId,
      date_submitted: dateSubmitted,
      accept: accept,
    }),
  };
  const response = await fetch(
    apiEndpoint + "/respondToRentalRequest",
    requestOptions
  );

  if (!response.ok) {
    throw new Error("Failed to respond to rental request");
  }

  const responseJson = await response.json();
  return responseJson;
};

export const getRentalRequestsByOwner = async ({ ownerId }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
  };

  const response = await fetch(
    apiEndpoint + "/getRentalRequestsByOwner?owner_id=" + ownerId,
    requestOptions
  );
  const responseJson = await response.json();
  return responseJson;
};

export const getRentalRequestsByUser = async ({ userId }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
  };

  const response = await fetch(
    apiEndpoint + "/getRentalRequestsForAUser?user_id=" + userId,
    requestOptions
  );
  const responseJson = await response.json();
  return responseJson;
};

export const getRentalRecords = async ({ userId }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
  };

  const response = await fetch(
    apiEndpoint + "/getRentalRecords?user_id=" + userId,
    requestOptions
  );
  const responseJson = await response.json();
  return responseJson;
};

export const cancelRentalRequest = async ({ rentalRequest }) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
    body: JSON.stringify({
      partition_key: rentalRequest.partition_key,
      sort_key: rentalRequest.sort_key,
    }),
  };
  const response = await fetch(
    apiEndpoint + "/deleteRentalRequest",
    requestOptions
  );

  if (!response.ok) {
    throw new Error("Failed to respond to rental request");
  }

  const responseJson = await response.json();
  return responseJson;
};
