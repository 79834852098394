import React, { useState, useEffect } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PendingRentalRequestsList from "./PendingRentalRequestsList";
import RejectedRentalRequestsList from "./RejectedRentalRequestsList";

import * as api from "../../../../utils/api";

const InactiveRentalRequests = () => {
  const [pendingRentalRequests, setPendingRentalRequests] = useState([]);
  const [rejectedRentalRequests, setRejectedRentalRequests] = useState([]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        const currentUserId = localStorage.getItem("sessionUser");
        const response = await api.getRentalRequestsByUser({
          userId: currentUserId,
        });
        setPendingRentalRequests(response.rental_requests.unaddressed);
        setRejectedRentalRequests(response.rental_requests.rejected);

        localStorage.setItem(
          "rentalRequestsByUser",
          JSON.stringify({
            rentalRequests: response.rental_requests,
            isCacheDirty: false,
            timestamp: new Date(),
          })
        );
      };

      // Check the cache for rental requests, and make sure it's still valid.
      const rentalRequests = localStorage.getItem("rentalRequestsByUser");
      const rentalRequestsJson = rentalRequests
        ? JSON.parse(rentalRequests)
        : null;

      console.log(rentalRequestsJson);

      // Clear cache after 5 mins
      const nMinsFromNow = new Date(new Date().getTime() + 5 * 60000);
      if (
        !rentalRequestsJson ||
        (rentalRequestsJson &&
          (rentalRequestsJson.isCacheDirty ||
            new Date(rentalRequestsJson.timestamp) > nMinsFromNow))
      ) {
        fetchData();
      } else {
        setPendingRentalRequests(rentalRequestsJson.rentalRequests.unaddressed);
        setRejectedRentalRequests(rentalRequestsJson.rentalRequests.rejected);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Accordion sx={{ width: "100%" }} elevation={0} variant="outlined">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Inactive Rental Requests</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <Typography>Pending Requests</Typography>
          <PendingRentalRequestsList
            pendingRentalRequests={pendingRentalRequests}
          />

          <Typography>Rejected Requests</Typography>
          <RejectedRentalRequestsList
            rejectedRentalRequests={rejectedRentalRequests}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default InactiveRentalRequests;
