import React, { useState } from "react";

import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Link,
  Typography,
} from "@mui/material";

import { format } from "date-fns";

import * as api from "../../../../utils/api";

const RentalRequestCard = ({
  rentalRequest: {
    status,
    date_submitted,
    owner_id,
    item_id,
    item_title,
    message,
    requested_start_date,
    requested_end_date,
    user: { profile_id, first_name, last_name, profile_picture },
  },
}) => {
  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const submitRequest = async ({ accept }) => {
    setIsLoading(true);
    try {
      await api.respondToRentalRequest({
        itemId: item_id,
        ownerId: owner_id,
        dateSubmitted: date_submitted,
        accept: accept,
      });
      setIsError(false);
      setSuccessMessage(
        `Successfully ${accept ? "accepted" : "rejected"} rental request`
      );
    } catch (err) {
      console.log(err);
      setIsError(true);
    }
    setIsLoading(false);
  };

  const setCacheDirty = () => {
    localStorage.setItem(
      "rentalRequests",
      JSON.stringify({ isCacheDirty: true })
    );
  };

  return (
    <Card elevation={0}>
      <CardHeader
        title={
          <Typography>
            <Link
              href={`/profile/${profile_id}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {`${first_name} ${last_name} `}
            </Link>
            requested to borrow your{" "}
            <Link
              href={`/listing/${item_id}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {item_title}
            </Link>
          </Typography>
        }
        titleTypographyProps={{ variant: "h5" }}
        subheader={
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Typography>
                {format(new Date(requested_start_date), "PP")} -{" "}
                {format(new Date(requested_end_date), "PP")}
              </Typography>
            </Box>

            <Typography variant="body1">
              Request submitted on {format(new Date(date_submitted), "PP")}
            </Typography>
          </Box>
        }
        avatar={<Avatar src={profile_picture} />}
      />
      <CardContent>
        <Typography gutterBottom variant="body1">
          {message}
        </Typography>
      </CardContent>

      {status === "UNADDRESSED" ? (
        <CardActions
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {isError ? (
            <Alert severity="error">Oops something went wrong.</Alert>
          ) : null}

          {successMessage ? (
            <Alert severity="success">{successMessage}</Alert>
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={async () => {
                  submitRequest({ accept: true });
                  setCacheDirty();
                }}
                fullWidth
              >
                Accept
              </Button>
              <Button
                fullWidth
                variant="outlined"
                disabled={isLoading}
                onClick={async () => {
                  submitRequest({ accept: false });
                  setCacheDirty();
                }}
              >
                Reject
              </Button>
            </Box>
          )}
        </CardActions>
      ) : null}
    </Card>
  );
};

export default RentalRequestCard;
