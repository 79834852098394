import React from "react";

import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";

import PendingRentalRequestsListItem from "./PendingRentalRequestsListItem";

const PendingRentalRequestsList = ({ pendingRentalRequests }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Item</TableCell>
          <TableCell>From</TableCell>
          <TableCell>To</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {pendingRentalRequests.map((row) => (
          <PendingRentalRequestsListItem
            row={row}
            key={`${row.partition_key}-${row.sort_key}`}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default PendingRentalRequestsList;
