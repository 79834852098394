const categories = [
  "Apparel",
  "Electronics",
  "Entertainment",
  "Garden & Outdoor",
  "Hobbies",
  "Home Goods",
  "Home Improvement Supplies",
  "Kitchen Appliances",
  "Musical Instruments",
  "Office Supplies",
  "Sporting Goods",
  "Toys & Games",
];

export default categories;
