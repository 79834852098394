import {
  Box,
  Button,
  Container,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Typography,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

import ImageStepper from "../ImageStepper";

const ItemCard = ({
  // Item
  item_id,
  title,
  description,
  imageUrls,
  dateCreated,
  isAvailable,
  location,
  isUserOwner,
}) => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md" sx={{ marginTop: "40px" }}>
      <Card sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
        <Container>
          <CardHeader
            title={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {title}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <LocationOnIcon fontSize="small" />
                  <Typography variant="body1">{location}</Typography>
                </Box>
              </Box>
            }
            titleTypographyProps={{ variant: "h5" }}
            subheader={
              <div>
                {isUserOwner ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    {isAvailable ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <CheckCircleIcon fontSize="small" />
                        <Typography variant="body1">Available</Typography>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <CancelIcon fontSize="small" />
                        <Typography variant="body1">Unavailable</Typography>
                      </Box>
                    )}
                    <Typography variant="body1">
                      Posted on {format(new Date(dateCreated), "PP")}
                    </Typography>
                  </Box>
                ) : null}
              </div>
            }
          />
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ImageStepper imageUrls={imageUrls} />
              </Box>

              <Typography variant="h6">Description</Typography>
              <Typography variant="body1">{description}</Typography>
            </Box>
          </CardContent>

          <CardActions
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {isUserOwner ? (
              // If the user is the owner, show the button that takes them to the edit page
              <Button
                variant="contained"
                onClick={async () => {
                  navigate(`/listing/${item_id}/edit`);
                }}
              >
                Edit
              </Button>
            ) : null}
          </CardActions>
        </Container>
      </Card>
    </Container>
  );
};

export default ItemCard;
