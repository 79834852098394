import { ThemeProvider } from "@emotion/react";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

import ProtectedRoute from "./components/ProtectedRoute";

import Home from "./views/Home";
import Listing from "./views/Listing";
import EditListing from "./views/Listing/EditListing";
import CreateListing from "./views/Listing/CreateListing";
import Listings from "./views/Listings";
import SearchResults from "./views/Listings/SearchResults";
import Account from "./views/Account";

import Login from "./views/Auth/Login";
import Register from "./views/Auth/Register";
import VerifyAccount from "./views/Auth/Register/VerifyAccount";
import ForgotPassword from "./views/Auth/ForgotPassword";
import ResetPassword from "./views/Auth/ResetPassword";

import Profile from "./views/Profile";
import PublicProfile from "./views/Profile/PublicProfile";

import NotFound from "./views/Errors/NotFound";

import WIP from "./views/WIP";

import theme from "./theme";

import * as utils from "./utils/utils";

const password = localStorage.getItem("password");
const isValidPassword = utils.isValidPassword({ password });

const WipRouting = (
  <ThemeProvider theme={theme}>
    <Router>
      <Routes>
        <Route path="*" element={<WIP />} />
      </Routes>
    </Router>
  </ThemeProvider>
);

const routing = (
  <ThemeProvider theme={theme}>
    <Router>
      <Routes>
        {/* Home */}
        <Route exact path="/" element={<Home />} />

        {/* Auth */}
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/verify-account" element={<VerifyAccount />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/forgot-password/reset" element={<ResetPassword />} />

        {/* Listing */}
        <Route path="/listing/:item_id" element={<Listing />} />
        <Route
          path="/listing/new"
          element={
            <ProtectedRoute>
              <CreateListing />
            </ProtectedRoute>
          }
        />
        <Route
          path="/listing/:item_id/edit"
          element={
            <ProtectedRoute>
              <EditListing />
            </ProtectedRoute>
          }
        />

        {/* Listings */}
        <Route path="/listings" element={<Listings />} />
        <Route path="/listings/search" element={<SearchResults />} />

        {/* Account */}
        <Route
          path="/account"
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        />

        {/* Profile */}
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route path="/profile/:profile_id" element={<PublicProfile />} />

        {/* Errors */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  </ThemeProvider>
);

ReactDOM.render(
  isValidPassword ? routing : WipRouting,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
