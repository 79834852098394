import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import * as cognito from "../../../utils/cognito";

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Container,
  Link,
  TextField,
  Typography,
} from "@mui/material";

import PageWrapper from "../../../components/Wrappers/PageWrapper";
import SearchProfileHeader from "../../../components/Headers/SearchProfileHeader";

const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <PageWrapper>
      <SearchProfileHeader />

      <Container maxWidth="md" sx={{ marginTop: "40px" }}>
        <Card sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <Container>
            <CardHeader
              title="Login"
              titleTypographyProps={{ variant: "h5" }}
            />
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  name="email"
                  value={email}
                  onChange={(event) => {
                    setIsError(false);
                    setEmail(event.target.value);
                  }}
                  fullWidth
                />
                <TextField
                  id="password"
                  label="Password"
                  variant="outlined"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(event) => {
                    setIsError(false);
                    setPassword(event.target.value);
                  }}
                  fullWidth
                />
              </Box>
            </CardContent>
            <CardActions
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "center",
              }}
            >
              {isError ? <Alert severity="error">{errorMessage}</Alert> : null}

              <Link href="/forgot-password">
                <Typography variant="body1">Forgot Password?</Typography>
              </Link>

              <Button
                variant="contained"
                sx={{ width: "50%" }}
                disabled={isLoading}
                onClick={async () => {
                  setIsLoading(true);

                  try {
                    await cognito.signInWithEmail(email, password);

                    cognito.getSessionUserId().then((sessionId) => {
                      localStorage.setItem("sessionUser", sessionId);
                    });

                    navigate("/");
                  } catch (err) {
                    setIsError(true);
                    setErrorMessage(
                      "Oops! Email and password combination was incorrect, please try again."
                    );
                  }

                  setIsLoading(false);
                }}
              >
                Log In
              </Button>
              <Button
                variant="outlined"
                sx={{ width: "50%" }}
                component={RouterLink}
                to="/register"
              >
                Register
              </Button>
            </CardActions>
          </Container>
        </Card>
      </Container>
    </PageWrapper>
  );
};

export default Login;
