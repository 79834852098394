import React from "react";

import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  Card,
  CardHeader,
  CardContent,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { format } from "date-fns";

const ContactCard = ({
  // Data
  username,
  firstName,
  lastName,
  dateCreated,
  profilePicture,
  isUserLoggedIn,

  // Borrow request stuff
  borrowRequestMessage,
  setBorrowRequestMessage,
  onSubmitBorrowRequest,
  requestedStartDate,
  setRequestedStartDate,
  requestedEndDate,
  setRequestedEndDate,

  // Errors
  successSubmittingRequest,
  errorSubmittingRequest,
  setIsErrorSubmittingRequest,
  errorMessage,

  // isLoading
  isLoading,

  // Disable certain dates
  unavailableDates,
}) => {
  return (
    <Container maxWidth="md" sx={{ marginTop: "10px" }}>
      <Card sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Container>
          <CardHeader
            title={
              <Link
                href={`/profile/${username}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {`${firstName} ${lastName}`}
              </Link>
            }
            titleTypographyProps={{ variant: "h6" }}
            subheader={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1">Owner</Typography>
                {dateCreated ? (
                  <Typography variant="body1">
                    Member since {format(new Date(dateCreated), "MMMM yyyy")}
                  </Typography>
                ) : null}
              </Box>
            }
            avatar={<Avatar src={profilePicture} />}
          />
          <CardContent>
            {isUserLoggedIn ? (
              <div>
                {successSubmittingRequest ? (
                  <Alert severity="success">Borrow request submitted.</Alert>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Start date"
                          disablePast
                          value={requestedStartDate}
                          onChange={(newValue) => {
                            setRequestedStartDate(newValue);
                            setIsErrorSubmittingRequest(false);
                          }}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                          shouldDisableDate={(date) =>
                            unavailableDates.has(format(date, "PP"))
                          }
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="End date"
                          disablePast
                          value={requestedEndDate}
                          onChange={(newValue) => {
                            setRequestedEndDate(newValue);
                            setIsErrorSubmittingRequest(false);
                          }}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                          shouldDisableDate={(date) =>
                            unavailableDates.has(format(date, "PP"))
                          }
                        />
                      </LocalizationProvider>
                    </Box>
                    <TextField
                      id="borrowRequestMessage"
                      label="Message"
                      variant="outlined"
                      name="borrowRequestMessage"
                      value={borrowRequestMessage}
                      onChange={(event) => {
                        setBorrowRequestMessage(event.target.value);
                        setIsErrorSubmittingRequest(false);
                      }}
                      multiline
                      fullWidth
                    />

                    {errorSubmittingRequest ? (
                      <Alert severity="error">{errorMessage}</Alert>
                    ) : null}

                    <Button
                      variant="contained"
                      onClick={() => onSubmitBorrowRequest()}
                      disabled={isLoading}
                    >
                      Request to borrow
                    </Button>
                  </Box>
                )}
              </div>
            ) : (
              <div>
                <Typography>Log in to rent this item</Typography>
              </div>
            )}
          </CardContent>
        </Container>
      </Card>
    </Container>
  );
};

export default ContactCard;
