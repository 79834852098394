import React from "react";

import { Box, Container } from "@mui/material";

const HorizontalBand = ({ text, image }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#A6D785",
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 15px 60px -12px inset",
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { xs: "space-around", md: "space-between" },
            width: "100%",
            alignItems: "center",
          }}
        >
          {text}
          <img src={image} alt="marketing" />
        </Box>
      </Container>
    </Box>
  );
};

export default HorizontalBand;
