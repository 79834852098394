import React, { useState } from "react";

import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Typography,
} from "@mui/material";

import SelectLocation from "../SelectLocation";

const SelectLocationHome = ({ onSelectLocation }) => {
  const [location, setLocation] = useState("");

  const [isError, setIsError] = useState(false);

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginTop: "40px",
        }}
      >
        <Typography variant="body1">
          Welcome to RNTYR, the lending and borrowing platform.
        </Typography>

        <Card>
          <CardHeader
            title="Select a location"
            titleTypographyProps={{ variant: "body1" }}
            sx={{ backgroundColor: "#A6D785" }}
          />
          <CardContent>
            <Typography variant="body1" gutterBottom>
              To see available items or post your own listing, select a city.
            </Typography>
            <SelectLocation
              value={location}
              onLocationChange={(value) => {
                setLocation(value);
              }}
              disableError={() => setIsError(false)}
            />
          </CardContent>
          <CardActions
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {isError ? (
              <Alert severity="error">Please select a location.</Alert>
            ) : null}

            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                if (!location) {
                  setIsError(true);
                  return;
                }
                localStorage.setItem("location", location);
                onSelectLocation(location);
              }}
            >
              Search
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Container>
  );
};

export default SelectLocationHome;
