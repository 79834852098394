import { Box, Button, MobileStepper } from "@mui/material";
import { useState } from "react";

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const ImageStepper = ({ imageUrls }) => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = imageUrls?.length ?? 0;

  const handleNext = () => {
    setActiveStep(
      (prevActiveStep) => ((prevActiveStep % maxSteps) + 1) % maxSteps
    );
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) =>
        ((prevActiveStep % maxSteps) + maxSteps - 1) % maxSteps
    );
  };

  return imageUrls ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "500px",
      }}
    >
      <img
        src={imageUrls[activeStep]}
        alt="active"
        height="300px"
        width="auto"
      />
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        backButton={
          <Button size="small" onClick={handleBack}>
            <KeyboardArrowLeft />
            Back
          </Button>
        }
        nextButton={
          <Button size="small" onClick={handleNext}>
            Next
            <KeyboardArrowRight />
          </Button>
        }
      />
    </Box>
  ) : null;
};

export default ImageStepper;
