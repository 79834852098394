import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";

import ItemPreviewCard from "../ItemPreviewCard";

import * as api from "../../utils/api";

const LatestItems = ({ location }) => {
  const [items, setItems] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    try {
      setIsLoading(true);
      const fetchData = async () => {
        const response = await api.getLatestListings({
          location: location,
          limit: 3,
        });
        setItems(response.items);
        setIsLoading(false);
      };
      fetchData();
    } catch (err) {
      console.log(err);
    }
  }, [location]);

  return (
    <Box>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div>
          {items.length > 0 ? (
            <Box>
              <Typography variant="body1" gutterBottom>
                New items in <b>{location}</b>
              </Typography>
              <Grid
                width="80%"
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {items.map(({ item_id, location, title, photos }, index) => (
                  <Grid item xs={12} sm={4} md={4} key={index}>
                    <ItemPreviewCard
                      itemId={item_id}
                      name={title}
                      location={location}
                      imageUrl={photos[0]}
                    />
                  </Grid>
                ))}
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  padding: "20px",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  component={RouterLink}
                  to={`/listings/search?location=${location}&category=${"All categories"}&query=`}
                  size="large"
                >
                  View All
                </Button>
              </Box>
            </Box>
          ) : (
            // If no (new) items, prompt the user to add some
            <Box>
              <Typography variant="body1" gutterBottom>
                No new items in <b>{location}</b>!
              </Typography>
              <Button
                variant="contained"
                size="medium"
                color="secondary"
                component={RouterLink}
                to="/listing/new"
              >
                Create listing
              </Button>
            </Box>
          )}
        </div>
      )}
    </Box>
  );
};

export default LatestItems;
