import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Container,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import LogoProfileHeader from "../../components/Headers/LogoProfileHeader";
import PageWrapper from "../../components/Wrappers/PageWrapper";
import MyListingsTab from "../../components/ListingsTabs/MyListingsTab";
import RentalRequestsTab from "../../components/ListingsTabs/RentalRequestsTab";
import RentalRecordsTab from "../../components/ListingsTabs/RentalRecordsTab";

// Displays the listings owned by a user
const Listings = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const tab = parseInt(new URLSearchParams(search).get("tab"));

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    setTabValue(tab && tab <= 2 ? tab : 0);
  }, [tab]);

  const TabPanel = ({ value, index, children }) => {
    return value === index ? children : null;
  };

  return (
    <PageWrapper>
      <LogoProfileHeader />

      <Container maxWidth="md" sx={{ marginTop: "40px" }}>
        <Card sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <Container>
            <CardHeader
              title={
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    marginBottom: "10px",
                  }}
                >
                  <Tabs
                    value={tabValue}
                    onChange={(event, newValue) => {
                      setTabValue(newValue);
                      navigate({ to: "/listings", search: `?tab=${newValue}` });
                    }}
                  >
                    <Tab
                      label={<Typography variant="h6">My listings</Typography>}
                      id="my-listings-tab"
                    />
                    <Tab
                      label={
                        <Typography variant="h6">My rental requests</Typography>
                      }
                      id="my-rental-requests-tab"
                    />
                    <Tab
                      label={<Typography variant="h6">My rentals</Typography>}
                      id="my-rental-rentals-tab"
                    />
                  </Tabs>
                </Box>
              }
            />
            <CardContent>
              <TabPanel value={tabValue} index={0}>
                <MyListingsTab />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <RentalRequestsTab />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <RentalRecordsTab />
              </TabPanel>
            </CardContent>
          </Container>
        </Card>
      </Container>
    </PageWrapper>
  );
};

export default Listings;
