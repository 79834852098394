import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Container,
  Link,
  TextField,
  Typography,
} from "@mui/material";

import * as cognito from "../../../utils/cognito";

import PageWrapper from "../../../components/Wrappers/PageWrapper";
import SearchProfileHeader from "../../../components/Headers/SearchProfileHeader";

const ForgotPassword = () => {
  const location = useLocation();

  let email;
  try {
    email = location.state.email;
  } catch (err) {
    email = null;
  }

  const [resetCode, setResetCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <PageWrapper>
      <SearchProfileHeader />
      <Container maxWidth="md" sx={{ marginTop: "40px" }}>
        <Card sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <Container>
            <CardHeader
              title="Enter new password"
              titleTypographyProps={{ variant: "h5" }}
              subheader="Enter the reset code we sent to your email."
              subheaderTypographyProps={{ variant: "body1" }}
            />
            {!email ? (
              <Alert severity="error">
                An error occurred. Please try again.
              </Alert>
            ) : (
              <div>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <TextField
                      id="reset_code"
                      label="Reset code"
                      variant="outlined"
                      name="resetCode"
                      value={resetCode}
                      onChange={(event) => {
                        setIsError(false);
                        setResetCode(event.target.value);
                      }}
                      fullWidth
                    />
                    <TextField
                      id="new_password"
                      label="New password"
                      type="password"
                      variant="outlined"
                      name="password"
                      value={password}
                      onChange={(event) => {
                        setIsError(false);
                        setPassword(event.target.value);
                      }}
                      fullWidth
                    />
                    <TextField
                      id="confirm_new_password"
                      label="Confirm new password"
                      type="password"
                      variant="outlined"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(event) => {
                        setIsError(false);
                        setConfirmPassword(event.target.value);
                      }}
                      fullWidth
                    />
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {isError ? (
                    <Alert severity="error">{errorMessage}</Alert>
                  ) : null}

                  {isSuccess ? (
                    <Alert severity="success">
                      Your password has been successfully reset!{" "}
                      <Link href="/login">
                        <Typography>
                          Log in with your new password details.
                        </Typography>
                      </Link>
                    </Alert>
                  ) : null}

                  <Button
                    variant="contained"
                    sx={{ width: "50%" }}
                    disabled={isLoading}
                    onClick={async () => {
                      setIsLoading(true);

                      // Validate that the password is long enough
                      if (password.length < 8) {
                        setIsError(true);
                        setErrorMessage(
                          "Password must be at least 8 characters long."
                        );
                        setIsLoading(false);
                        return;
                      }
                      // Validate that the password matches the confirmPassword
                      if (password !== confirmPassword) {
                        setIsError(true);
                        setErrorMessage("Passwords must match.");
                        setIsLoading(false);
                        return;
                      }

                      try {
                        await cognito.forgotPassword(
                          email,
                          resetCode,
                          password
                        );
                        setIsSuccess(true);
                      } catch (err) {
                        console.log(err);
                        setIsError(true);
                        setErrorMessage(
                          "Unable to reset password. Check that the reset code is valid."
                        );
                      }
                      setIsLoading(false);
                    }}
                  >
                    Reset your password
                  </Button>
                </CardActions>
              </div>
            )}
          </Container>
        </Card>
      </Container>
    </PageWrapper>
  );
};

export default ForgotPassword;
