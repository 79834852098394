import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { format } from "date-fns";

const RentalRecordsList = ({ title, rentalHistory, defaultExpanded }) => {
  return (
    <Accordion defaultExpanded={defaultExpanded} sx={{ width: "100%" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell>Owner</TableCell>
              <TableCell>Date borrowed</TableCell>
              <TableCell>Date returned</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rentalHistory.map((row) => (
              <TableRow
                key={`${row.user_id}-${row.date_borrowed}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Link
                    href={`/listing/${row.item_id}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {`${row.item_title}`}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    href={`/profile/${row.user.profile_id}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {`${row.user.first_name} ${row.user.last_name}`}
                  </Link>
                </TableCell>
                <TableCell>
                  {format(new Date(row.date_borrowed), "PP")}
                </TableCell>
                <TableCell>
                  {format(new Date(row.date_returned), "PP")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};

export default RentalRecordsList;
