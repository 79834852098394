import React from "react";
import HomeFooter from "../../Footers/HomeFooter";

const HomeWrapper = ({ children }) => {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>{children}</div>
      <HomeFooter />
    </div>
  );
};

export default HomeWrapper;
