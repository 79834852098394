import React from "react";

import { Box, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";

import categories from "../../assets/data/categories";

import CategoryCard from "./CategoryCard";

const shades = [
  "#95c278",
  "#648150",
  "#324028",
  "#11150d",
  "#425635",
  "#85ac6a",
  "#000000",
  "#74975d",
  "#a6d785",
  "#212b1b",
  "#536c43",
];

const CategoriesCarousel = () => {
  // TODO: hardcode these groups, there's no need to calculate them every time
  // Groups them into 4
  const groupCategories = () => {
    let retval = [];

    for (let i = 0; i < categories.length; i = i + 4) {
      retval = [
        ...retval,
        [
          categories[i],
          categories[i + 1] ?? null,
          categories[i + 2] ?? null,
          categories[i + 3] ?? null,
        ],
      ];
    }

    return retval;
  };

  const groupAndFormat = (arr) => {
    return arr.map((group, i) => {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: "10px",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {group.map((item, j) => {
            return (
              <CategoryCard
                key={i + j}
                title={item}
                backgroundColour={shades[i + (j % shades.length)]}
              />
            );
          })}
        </Box>
      );
    });
  };

  return (
    <div>
      <Typography variant="body1" sx={{ marginBottom: "10px" }}>
        Browse categories
      </Typography>
      <Carousel
        autoPlay={false}
        animation="slide"
        indicators={false}
        navButtonsAlwaysVisible={true}
      >
        {groupAndFormat(groupCategories())}
      </Carousel>
    </div>
  );
};

export default CategoriesCarousel;
