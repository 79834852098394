import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { Box, Button, Container, Typography } from "@mui/material";

import SearchProfileHeader from "../../components/Headers/SearchProfileHeader";
import LatestItems from "../../components/LatestItems";
import SelectLocationHome from "../../components/SelectLocationHome";
import CategoriesCarousel from "../../components/CategoriesCarousel";
import HorizontalBand from "../../components/HomeComponents/HorizontalBand";
import HomeWrapper from "../../components/Wrappers/HomeWrapper";

import books from "../../assets/images/books.png";
import hammer from "../../assets/images/hammer.png";

const Home = () => {
  const [location, setLocation] = useState(
    localStorage.getItem("location") ?? "" // Lyon, Rhône, France
  );

  return (
    <HomeWrapper>
      <SearchProfileHeader
        query={""}
        location={location}
        category={"All categories"}
      />
      {/* If location hasn't already been selected, select one */}
      {!location ? (
        <SelectLocationHome
          onSelectLocation={(value) => {
            setLocation(value);
          }}
        />
      ) : null}

      {location ? (
        <div>
          <HorizontalBand
            text={
              <Typography variant="h4">Why buy when you can borrow?</Typography>
            }
            image={hammer}
          />

          <Container sx={{ marginTop: "40px", marginBottom: "40px" }}>
            <LatestItems location={location} />
          </Container>

          <HorizontalBand
            text={
              <Box>
                <Typography variant="h5" gutterBottom>
                  Give your stuff a new life.
                </Typography>
                <Typography variant="body1">1. Dig up hobby goods</Typography>
                <Typography variant="body1">2. Post</Typography>
                <Typography variant="body1" gutterBottom>
                  3. Profit
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  component={RouterLink}
                  to="/listing/new"
                >
                  Make a post
                </Button>
              </Box>
            }
            image={books}
          />

          <Container sx={{ marginTop: "40px", marginBottom: "40px" }}>
            <CategoriesCarousel />
          </Container>
        </div>
      ) : null}
    </HomeWrapper>
  );
};

export default Home;
