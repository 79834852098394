import React from "react";

import { Box, Container } from "@mui/material";

import Logo from "../../Logo";
import ProfileBubble from "../../ProfileBubble";

export const LogoProfileHeader = () => {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        boxShadow: "0 5px 5px -5px rgba(50, 50, 93, 0.25)",
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Logo />
          <ProfileBubble />
        </Box>
      </Container>
    </div>
  );
};

export default LogoProfileHeader;
